import {
  BedType,
  DayType,
  HotelType,
  StayStatusType,
  UnitType,
} from 'generated/graphql'

export const bedTypeTranslator = (bedType?: BedType): string => {
  switch (bedType) {
    case BedType.SemiDouble:
      return 'セミダブル'
    case BedType.Double:
      return 'ダブル'
    case BedType.King:
      return 'クアッド'
    case BedType.Queen:
      return 'クイーン'
    case BedType.Single:
      return 'シングル'
    case BedType.SuperKing:
      return 'スーパーキング'
    case BedType.Twin:
      return 'ツイン'
    default:
      return '不明'
  }
}

export const unitTypeTranslator = (unitType: UnitType): string => {
  switch (unitType) {
    case UnitType.Group:
      return '団体'
    case UnitType.Hour:
      return '時間'
    case UnitType.Person:
      return '人'
    case UnitType.StayNight:
      return '泊'
    case UnitType.Times:
      return '回'
  }
}

export const dayTypeTranslator = (dayType: DayType): string => {
  switch (dayType) {
    case DayType.Monday:
      return '月曜日'
    case DayType.Tuesday:
      return '火曜日'
    case DayType.Wednesday:
      return '水曜日'
    case DayType.Thursday:
      return '木曜日'
    case DayType.Friday:
      return '金曜日'
    case DayType.Saturday:
      return '土曜日'
    case DayType.Sunday:
      return '日曜日'
    case DayType.BeforeHoliday:
      return '祝前日'
    case DayType.Holiday:
      return '祝日'
    default:
      return '不明'
  }
}

export const stayStatusTranslator = (
  stayStatus?: StayStatusType | null
): string => {
  switch (stayStatus) {
    case StayStatusType.TemporaryReservation:
      return '仮予約'
    case StayStatusType.Confirm:
      return '予約確定'
    case StayStatusType.DuringStay:
      return 'チェックイン済'
    case StayStatusType.AfterCheckOut:
      return 'チェックアウト済'
    case StayStatusType.Cancel:
      return 'キャンセル'
    case StayStatusType.NoShow:
      return 'ノーショー'
    default:
      return '不明'
  }
}

export const hotelTypeTranslator = (hotelType: HotelType): string => {
  switch (hotelType) {
    case HotelType.Hotel:
      return 'ホテル'
    case HotelType.Ryokan:
      return '旅館'
    case HotelType.Minpaku:
      return '民泊'
    case HotelType.Camp:
      return 'キャンプ'
    case HotelType.Cottage:
      return 'コテージ'
    case HotelType.GuestHouse:
      return 'ゲストハウス'
    case HotelType.Villa:
      return '別荘'
    case HotelType.Other:
      return 'その他'

    default:
      return '不明'
  }
}

export const feeFormatter = (fee: number): string => {
  return new Intl.NumberFormat('ja-JP', {
    style: 'decimal',
    currency: 'JPY',
  }).format(fee)
}
