import { Text, Link, Button, Box, HStack } from '@chakra-ui/react'
import React, { FC, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import styles from './style.module.scss'
import useGetInfoLists from 'src/util/useGetJammyInfoLists'
import dayjs from 'dayjs'

export const Information: FC = () => {
  const { infoLists } = useGetInfoLists()

  const latestInfo = useMemo(() => {
    if (!infoLists) return
    return infoLists[0]
  }, [infoLists])

  return (
    // FIXME: Jammyヘルプの最新のInfoを1件取得する
    <Box className={styles.informationBox}>
      <HStack>
        <FontAwesomeIcon
          icon={faCircleInfo}
          color="#3757FF"
          className={styles.icon}
        />
        <Text fontWeight="normal" pr="12px">
          {dayjs(latestInfo?.updatedAt).format('YYYY/MM/DD')}
        </Text>
      </HStack>
      <Link
        href={`https://help.jammy.jp/info/${latestInfo?.id}`}
        target="_blank"
      >
        <Text fontSize="16px" fontWeight="bold" textDecoration="underline">
          {latestInfo?.title}
        </Text>
      </Link>
      <Box className={styles.buttonBox}>
        <Link
          href={`https://help.jammy.jp/info/${latestInfo?.id}`}
          target="_blank"
          className={styles.linkButton}
        >
          <Button size="lg" colorScheme="blackAlpha" bgColor="#333">
            もっと見る
          </Button>
        </Link>
      </Box>
    </Box>
  )
}
