import { Text, Box } from '@chakra-ui/react'
import React, { FC } from 'react'
import LinkButton from 'src/components/Button/LinkButton'
import { useGetReservationListQuery } from 'generated/graphql'
import { LocalStorageKeys } from 'src/util/persistenceKeys'
import styles from './style.module.scss'
import { SectionTitle } from 'src/components/Text/Title'
import routes from 'src/util/routes'
import dayjs from 'dayjs'
import { feeFormatter } from 'src/util/translator'

export const Reservations: FC = () => {
  const hotelId = localStorage.getItem(
    LocalStorageKeys.currentManageHotelId
  ) as string
  const { data } = useGetReservationListQuery({
    variables: { hotelId },
    fetchPolicy: 'network-only',
  })

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>
        <SectionTitle>最新の予約状況</SectionTitle>
        <LinkButton href={routes.reservations.index}>予約一覧</LinkButton>
      </div>
      <div className={styles.body}>
        {data?.hotel?.reservationList?.items
          ?.slice(0, 6)
          .map((reservation, idx) => (
            <div className={styles.item} key={idx}>
              {reservation.customer ? (
                <Text className={styles.name}>
                  {reservation.customer.lastName}
                  {reservation.customer.firstName}様
                </Text>
              ) : (
                <Text className={styles.name}>
                  {reservation.lastName}
                  {reservation.firstName}様
                </Text>
              )}
              <Box className={styles.bottom}>
                <Text>
                  大人
                  {reservation.adultCount}名、子ども
                  {(reservation.childCount ?? 0) + (reservation.babyCount ?? 0)}
                  名/合計￥{feeFormatter(reservation.totalFee)}
                </Text>
              </Box>
              <Text className={styles.term}>
                滞在期間：
                {dayjs(reservation.checkIn).format('YYYY/MM/DD')}-{' '}
                {dayjs(reservation.checkOut).format('YYYY/MM/DD')}
              </Text>
              <Text className={styles.date}>
                予約受付日: {dayjs(reservation.createdAt).format('YYYY/MM/DD')}
              </Text>
            </div>
          ))}
      </div>
    </div>
  )
}
