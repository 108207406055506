import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'
import React, { FC } from 'react'
import styles from './style.module.scss'

type Props = Omit<ButtonProps, 'size' | 'color'> & {
  color?:
    | 'normal'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'error'
    | 'disabled'
    | 'redGraduation'
    | 'pinkGraduation'
  size?: 'medium' | 'large' | 'small'
}

export const Button: FC<Props> = ({ size = 'medium', ...props }) => {
  return (
    <ChakraButton
      {...props}
      data-color={props.color}
      data-size={size}
      className={styles.button}
    >
      {props.children}
    </ChakraButton>
  )
}
