import { useState, useEffect } from 'react'
import { getInfoListsFromCms, info } from './jammyHelpclient'

const useGetInfoLists = () => {
  const [infoLists, setInfoLists] = useState<info[] | false>(false)
  useEffect(() => {
    const getData = async () => {
      const res = await getInfoListsFromCms()
      setInfoLists(res.data.infoLists)
    }
    getData()
  }, [])

  return { infoLists }
}

export default useGetInfoLists
