import React, { FC } from 'react'
import { Stack, Text, HStack } from '@chakra-ui/react'
import { PageTitle } from '../Text/Title'
import dayjs from 'dayjs'
import { LocalStorageKeys } from 'src/util/persistenceKeys'
import { StartGuide } from 'src/components/Dashboard/StartGuide'
import { Reservations } from 'src/components/Dashboard/Reservations'
import 'dayjs/locale/ja'
import { LargeText } from '../Text'
import { Information } from './Information'
import { Todos } from './Todos'

export const DashBoard: FC = () => {
  dayjs.locale('ja')
  const hotelId = localStorage.getItem(
    LocalStorageKeys.currentManageHotelId
  ) as string
  // const { data, loading } = useGetReservationListQuery({
  //   variables: { hotelId },
  // })

  // if (loading) return <Loading />

  return (
    <Stack spacing="24px" p="40px 24px">
      {/* <Box>
        {!data?.hotel?.paymentMethod && (
          <Alert
            status="error"
            justifyContent="space-between"
            px="20px"
            mb="8px"
          >
            <Flex>
              <AlertIcon />
              <Text  fontWeight="bold">
                支払い方法が登録されていません！
              </Text>
            </Flex>
            <LinkButton href={routes.hotelSetting.paymentMethod}>
              支払い設定へ
            </LinkButton>
          </Alert>
        )}

        {!data?.hotel?.bankAccount && (
          <Alert status="error" justifyContent="space-between" px="20px">
            <Flex>
              <AlertIcon />
              <Text  fontWeight="bold">
                口座情報が登録されていません！
              </Text>
            </Flex>
            <LinkButton href={routes.hotelSetting.bankAccount.detail}>
              口座設定へ
            </LinkButton>
          </Alert>
        )}
      </Box> */}
      <Information />
      <HStack spacing="24px">
        <PageTitle>ホーム</PageTitle>
        <LargeText p="8px 12px" bg="white" borderRadius="4px">
          {dayjs().format('YYYY月MM年DD日 (ddd)')}
        </LargeText>
      </HStack>
      <Text>お疲れ様です。今日もがんばりましょう😃</Text>

      {/* FIXME: propsでreservationを受け取る */}

      <Todos />

      <Reservations />

      <StartGuide />
    </Stack>
  )
}
