import { Text, TextProps } from '@chakra-ui/react'
import { FC } from 'react'

export const PageTitle: FC<TextProps> = (props) => {
  return (
    <Text
      as="h1"
      fontSize={{ md: '32px', base: '24px' }}
      fontWeight="900"
      {...props}
    >
      {props.children}
    </Text>
  )
}

export const SectionTitle: FC<TextProps> = (props) => {
  return (
    <Text
      as="h2"
      fontSize={{ md: '20px', base: '18px' }}
      fontWeight="bold"
      {...props}
    >
      {props.children}
    </Text>
  )
}
