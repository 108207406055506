import { ApolloClient, gql, InMemoryCache } from '@apollo/client'

const jammyHelpClient = new ApolloClient({
  uri: 'https://api-ap-northeast-1.graphcms.com/v2/ckywm1ywx01l101z2bqu8bbac/master',
  cache: new InMemoryCache(),
})

type info = {
  id: string
  title: string
  updatedAt: string
  content: {
    html: string
  }
}

type infoLists = {
  infoLists: info[]
}

type infoContent = {
  info: info
}

export type { info, infoContent }

const getInfoListsQuery = gql`
  query getInfos {
    infoLists(orderBy: updatedAt_DESC) {
      id
      title
      updatedAt
    }
  }
`

const getInfoListsFromCms = async () => {
  const response = await jammyHelpClient.query<infoLists>({
    query: getInfoListsQuery,
  })
  return response
}

export { getInfoListsFromCms }
