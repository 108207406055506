import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react'
import { FC } from 'react'
import styles from './style.module.scss'

export const SectionWrap: FC<BoxProps> = (props) => (
  <Box className={styles.wrap}>{props.children}</Box>
)

export const SectionHead: FC<BoxProps> = (props) => (
  <Box className={styles.head} {...props}>
    {props.children}
  </Box>
)

export const SectionHeadBorder: FC<BoxProps> = (props) => (
  <Box className={styles.headBorder}>{props.children}</Box>
)

export const SectionHeadInner: FC<BoxProps> = (props) => (
  <Box className={styles.headInner}>{props.children}</Box>
)

export const SectionBody: FC<BoxProps> = (props) => (
  <Box className={styles.body}>{props.children}</Box>
)

export const SectionBodyBorder: FC<BoxProps> = (props) => (
  <Box className={styles.bodyBorder}>{props.children}</Box>
)

export const SectionHeadText: FC<TextProps> = (props) => (
  <Text fontSize="12px" color="#969FBE">
    {props.children}
  </Text>
)
export const SectionTwoColumn: FC = (props) => (
  <div className={styles.twoColumn}>{props.children}</div>
)

export const SectionMainColumn: FC<BoxProps> = (props) => (
  <Box className={styles.mainColumn}>{props.children}</Box>
)
export const SectionSubColumn: FC<BoxProps> = (props) => (
  <Box className={styles.subColumn}>{props.children}</Box>
)
