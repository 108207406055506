import { Text, TextProps } from '@chakra-ui/react'
import { FC } from 'react'

export const LargeText: FC<TextProps> = (props) => (
  <Text {...props} fontSize="16px">
    {props.children}
  </Text>
)

export const MediumText: FC<TextProps> = (props) => (
  <Text {...props}>{props.children}</Text>
)

export const SmallText: FC<TextProps> = (props) => (
  <Text {...props} fontSize="12px">
    {props.children}
  </Text>
)
