import { Text, HStack, Image } from '@chakra-ui/react'
import React, { FC } from 'react'
import routes from 'src/util/routes'
import Link from 'next/link'
import styles from './style.module.scss'
import { SectionTitle } from 'src/components/Text/Title'
import { SectionHead, SectionBody } from 'src/components/Section'
import { Button } from 'src/components/Button'

export const StartGuide: FC = () => {
  const startGuideArray = [
    {
      stepNum: '01',
      title: '基本設定',
      description:
        'まずは、基本設定をしましょう。あなたのホテルの施設情報と売上を入金する口座、支払いカード情報を登録してビジネスを開始する準備をしましょう。',
      href_01: routes.hotelSetting.edit,
      href_02: routes.hotelSetting.bankAccount.detail,
      href_03: routes.hotelSetting.paymentMethod,
      linktext_01: '①施設情報',
      linktext_02: '②口座登録',
      linktext_03: '③支払い情報登録',
      image: '/guide_01.svg',
    },
    {
      stepNum: '02',
      title: '部屋を登録',
      description:
        'お客様に提供し、予約を受け付ける部屋を登録しましょう。部屋タイプ（種類）登録後に、部屋の登録をしましょう。',
      href_01: routes.roomTypes.index,
      href_02: routes.rooms.index,
      linktext_01: '①部屋タイプ登録',
      linktext_02: '②部屋登録',
      image: '/guide_02.svg',
    },
    {
      stepNum: '03',
      title: 'プランを登録',
      description:
        '部屋の登録が済んだら、いよいよ販売するプランの準備です。ユーザーが予約を申し込みたくなるプランを作成しましょう。',
      href_01: routes.plans.index,
      linktext_01: 'プラン登録',
      image: '/guide_03.svg',
    },
  ]

  return (
    <>
      <SectionHead>
        <HStack justifyContent="space-between">
          <SectionTitle>スタートガイド</SectionTitle>
          <Button
            onClick={() =>
              window.open('https://jammy-help.vercel.app/', '_blank')
            }
            color="normal"
          >
            ヘルプ
          </Button>
        </HStack>
      </SectionHead>
      <SectionBody>
        <div className={styles.items}>
          {startGuideArray.map((startGuide, idx) => (
            <div className={styles.item} key={idx}>
              <div className={styles.itemInner}>
                <div className={styles.subtitle}>STEP {startGuide.stepNum}</div>
                <Text className={styles.title}>{startGuide.title}</Text>
                <Text className={styles.description}>
                  {startGuide.description}
                </Text>
                <HStack className={styles.linkgroup}>
                  {startGuide.href_01 && (
                    <Link href={startGuide.href_01}>
                      <a className={styles.link}>{startGuide.linktext_01}</a>
                    </Link>
                  )}
                  {startGuide.href_02 && (
                    <Link href={startGuide.href_02}>
                      <a className={styles.link}>{startGuide.linktext_02}</a>
                    </Link>
                  )}
                  {startGuide.href_03 && (
                    <Link href={startGuide.href_03}>
                      <a className={styles.link}>{startGuide.linktext_03}</a>
                    </Link>
                  )}
                </HStack>
              </div>
              <div>
                <Image
                  className={styles.image}
                  src={startGuide.image}
                  alt={startGuide.title}
                />
              </div>
            </div>
          ))}
        </div>
      </SectionBody>
    </>
  )
}
