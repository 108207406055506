import { Box, Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useGetReservationListQuery } from 'generated/graphql'
import { LocalStorageKeys } from 'src/util/persistenceKeys'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
} from '@fortawesome/free-solid-svg-icons'
import styles from './style.module.scss'
import Link from 'next/link'
import routes from 'src/util/routes'
import { SmallHeading } from 'src/components/Heading'
import LinkButton from 'src/components/Button/LinkButton'

const todos = [
  {
    type: 'checkin',
    name: '本日のチェックイン',
    icon: faArrowRightToBracket,
    href: routes.reservations.index,
  },
  {
    type: 'checkout',
    name: '本日のチェックアウト',
    icon: faArrowRightFromBracket,
    href: routes.reservations.index,
  },
]

export const Todos: FC = () => {
  const hotelId = localStorage.getItem(
    LocalStorageKeys.currentManageHotelId
  ) as string
  const { data } = useGetReservationListQuery({
    variables: { hotelId },
    fetchPolicy: 'network-only',
  })

  const todayDate = dayjs().format('YYYY/MM/DD')

  // 今日のチェックイン数
  let todayCheckinCount: number = 0

  data?.hotel?.reservationList?.items?.filter((reservation) => {
    dayjs(reservation.checkIn).format('YYYY/MM/DD') == todayDate &&
      todayCheckinCount++
  })

  // 今日のチェックアウト数
  let todayCheckoutCount: number = 0
  data?.hotel?.reservationList?.items?.filter((reservation) => {
    dayjs(reservation.checkOut).format('YYYY/MM/DD') == todayDate &&
      todayCheckoutCount++
  })

  return (
    <div className={styles.body}>
      <Box className={styles.itemWrap}>
        {todos.map((item, id) => (
          <Link href={item.href} key={id}>
            <a>
              <Flex alignItems="center" className={styles.item}>
                <FontAwesomeIcon icon={item.icon} className={styles.icon} />
                <SmallHeading ml="16px">{item.name}</SmallHeading>
                <SmallHeading>：</SmallHeading>
                <SmallHeading borderBottom="1px solid #282C39">
                  {item.type == 'checkin'
                    ? todayCheckinCount
                    : item.type == 'checkout'
                    ? todayCheckoutCount
                    : '-'}
                </SmallHeading>
                <SmallHeading borderBottom="1px solid #282C39">件</SmallHeading>
              </Flex>
            </a>
          </Link>
        ))}
      </Box>
      <LinkButton
        display={{ lg: 'block', base: 'none' }}
        mr="24px"
        href={routes.reservations.index}
        color="gray"
      >
        もっと見る
      </LinkButton>
    </div>
  )
}
