import { Button, ButtonProps } from '@chakra-ui/react'
import { useRouter } from 'next/dist/client/router'
import { LinkProps } from 'next/link'
import React, { FC } from 'react'
import styles from './style.module.scss'

type Props = LinkProps &
  Omit<ButtonProps, 'size' | 'color'> & {
    color?:
      | 'normal'
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'gray'
      | 'error'
      | 'disabled'
      | 'redGraduation'
      | 'pinkGraduation'
    size?: 'medium' | 'large' | 'small'
  }

const LinkButton: FC<Props> = ({ size = 'medium', ...props }) => {
  const router = useRouter()

  const onClick = () => {
    router.push(props.href)
  }
  return (
    <Button
      onClick={onClick}
      {...props}
      data-color={props.color}
      data-size={size}
      className={styles.button}
    >
      {props.children}
    </Button>
  )
}

export default LinkButton
